var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canVerb(_vm.resource, "index")
    ? _c(
        "div",
        { staticClass: "mx-1" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { align: "left" } },
                [
                  _c(
                    "b-button-group",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle:collapse-1",
                          arg: "collapse-1",
                        },
                      ],
                      staticClass: "my-2 filter-button-group",
                      attrs: { title: _vm.getDefaultFilterMessage() },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "when-open" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "funnel", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "when-closed" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "funnel", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v("\n        Filtra\n      "),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { align: "right" } },
                [
                  _vm.canVerb(_vm.resource, "store")
                    ? _c(
                        "b-button",
                        {
                          staticClass: "btn-create",
                          attrs: {
                            type: "button",
                            variant: "primary",
                            size: "sm",
                            title: "Crea",
                          },
                          on: { click: _vm.onAdd },
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "plus-circle",
                              "aria-hidden": "true",
                            },
                          }),
                          _vm._v("\n        Crea"),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { visible: "", id: "collapse-1" } },
            [
              _c(
                "b-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSearch(_vm.filterName)
                    },
                  },
                },
                [
                  _c(
                    "b-card",
                    { staticClass: "filter" },
                    [
                      _c("b-row", [
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "insurer_id",
                                label: "Compagnia",
                                options: _vm.companies,
                              },
                              model: {
                                value: _vm.filter.byInsurer.id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byInsurer, "id", $$v)
                                },
                                expression: "filter.byInsurer.id",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "salesman_id",
                                label: "Produttore",
                                options: _vm.salesmen,
                              },
                              model: {
                                value: _vm.filter.byBroker.id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byBroker, "id", $$v)
                                },
                                expression: "filter.byBroker.id",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("input", {
                              attrs: { type: "hidden", name: "claim_number" },
                              domProps: {
                                value: _vm.filter.byAttribute.number,
                              },
                            }),
                            _vm._v(" "),
                            _c("base-input", {
                              attrs: {
                                name: "clean_claim_number",
                                type: "text",
                                label: "Numero Sinistro",
                                value: _vm.decodedInputVal,
                                placeholder: "Inserisci un sinsitro",
                              },
                              on: { keyup: _vm.onInputEncodeValue },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-input", {
                              attrs: {
                                name: "policy_number",
                                type: "text",
                                label: "Numero Polizza",
                                placeholder: "Inserisci una polizza",
                              },
                              model: {
                                value: _vm.filter.byInsurancePolicy.number,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter.byInsurancePolicy,
                                    "number",
                                    $$v
                                  )
                                },
                                expression: "filter.byInsurancePolicy.number",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        [
                          _c("div", { staticClass: "form-group col-md-3" }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "b-button-toolbar",
                                  [
                                    _c(
                                      "b-button-group",
                                      [
                                        _c("base-input", {
                                          attrs: {
                                            name: "Anagrafica",
                                            label: "Anagrafica",
                                            readonly: true,
                                          },
                                          model: {
                                            value: _vm.form.inpt_label_registry,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "inpt_label_registry",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.inpt_label_registry",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "mt-4 btn-quick",
                                            attrs: {
                                              size: "sm",
                                              text: "Button",
                                              variant: "lisaweb",
                                              title: "Ricerca Veloce",
                                            },
                                            on: {
                                              click:
                                                _vm.openQuickSearchRegistry,
                                            },
                                          },
                                          [
                                            _c("b-icon", {
                                              attrs: { icon: "search" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _vm.form.inpt_label_registry
                                          ? _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "mt-4 btn-quick ml-1",
                                                attrs: {
                                                  size: "sm",
                                                  text: "Button",
                                                  variant: "lisaweb",
                                                  title: "Elimina Anagrafica",
                                                },
                                                on: {
                                                  click: _vm.resetRegistryId,
                                                },
                                              },
                                              [
                                                _c("b-icon", {
                                                  attrs: { icon: "trash" },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group col-md-3" }, [
                            _vm.registry_data
                              ? _c("span", {
                                  staticClass: "info",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.toInfoData(
                                        _vm.registry_data,
                                        "registry"
                                      )
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("quick-search-registry-modal", {
                            ref: "quickSearchRegistry",
                            on: { input: _vm.handleRegistryModalInput },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("b-row", [
                        _c(
                          "div",
                          { staticClass: "form-group col-md-3 align-self-end" },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle:collapse-1",
                                    arg: "collapse-1",
                                  },
                                ],
                                attrs: {
                                  type: "submit",
                                  variant: "lisaweb",
                                  size: "sm",
                                },
                              },
                              [_vm._v("Cerca")]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                staticClass: "btn-reset",
                                attrs: {
                                  type: "button",
                                  variant: "lisaweb",
                                  size: "sm",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onClearFilter(_vm.filterName)
                                  },
                                },
                              },
                              [_vm._v("Reset")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-button-group",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:collapse-1-inner",
                              arg: "collapse-1-inner",
                            },
                          ],
                          staticClass: "my-2 filter-button-group",
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "when-open" },
                            [
                              _c("b-icon", {
                                attrs: { icon: "funnel", "font-scale": "1.5" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "when-closed" },
                            [
                              _c("b-icon", {
                                attrs: { icon: "funnel", "font-scale": "1.5" },
                              }),
                            ],
                            1
                          ),
                          _vm._v("\n          Altri Filtri\n        "),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          staticClass: "mt-2",
                          attrs: { id: "collapse-1-inner" },
                        },
                        [
                          _c("b-row", [
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-input", {
                                  attrs: {
                                    name: "place",
                                    type: "text",
                                    label: "Luogo",
                                    placeholder: "Inserisci un luogo",
                                  },
                                  model: {
                                    value: _vm.filter.byAttribute.place,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter.byAttribute,
                                        "place",
                                        $$v
                                      )
                                    },
                                    expression: "filter.byAttribute.place",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group col-md-3" },
                              [
                                _c("base-datepicker", {
                                  attrs: {
                                    name: "occurred_at",
                                    label: "Data Accadimento Sinistro",
                                  },
                                  model: {
                                    value: _vm.filter.byOccurrence.date,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter.byOccurrence,
                                        "date",
                                        $$v
                                      )
                                    },
                                    expression: "filter.byOccurrence.date",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("b-row", [
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-select", {
                                  attrs: {
                                    name: "risks",
                                    label: "Tipo",
                                    options: _vm.risks,
                                    taggable: true,
                                    multiple: true,
                                    closeOnSelect: false,
                                  },
                                  on: { input: _vm.onInputRisk },
                                  model: {
                                    value: _vm.filter.byRiskType.id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filter.byRiskType, "id", $$v)
                                    },
                                    expression: "filter.byRiskType.id",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mb-2",
                                    attrs: {
                                      pressed: _vm.myToggleR,
                                      variant: "light",
                                    },
                                    on: {
                                      "update:pressed": function ($event) {
                                        _vm.myToggleR = $event
                                      },
                                      click: function ($event) {
                                        _vm.myToggleR
                                          ? _vm.selectAllRisks()
                                          : _vm.deselectAllRisks()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.myToggleR
                                          ? "Deseleziona tutti"
                                          : "Seleziona tutti"
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-select", {
                                  attrs: {
                                    name: "risk_branches",
                                    label: "Ramo Polizza",
                                    options: _vm.risk_branches,
                                    multiple: true,
                                    taggable: true,
                                    closeOnSelect: false,
                                  },
                                  on: { input: _vm.onInputBranch },
                                  model: {
                                    value: _vm.filter.byRiskBranch.id,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter.byRiskBranch,
                                        "id",
                                        $$v
                                      )
                                    },
                                    expression: "filter.byRiskBranch.id",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mb-2",
                                    attrs: {
                                      pressed: _vm.myToggleB,
                                      variant: "light",
                                    },
                                    on: {
                                      "update:pressed": function ($event) {
                                        _vm.myToggleB = $event
                                      },
                                      click: function ($event) {
                                        _vm.myToggleB
                                          ? _vm.selectAllBranches()
                                          : _vm.deselectAllBranches()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.myToggleB
                                          ? "Deseleziona tutti"
                                          : "Seleziona tutti"
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-select", {
                                  attrs: {
                                    name: "prdct",
                                    label: "Prodotto",
                                    options: _vm.prdct,
                                    taggable: true,
                                    multiple: true,
                                    closeOnSelect: false,
                                  },
                                  model: {
                                    value: _vm.filter.byInsuranceRisk.id,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter.byInsuranceRisk,
                                        "id",
                                        $$v
                                      )
                                    },
                                    expression: "filter.byInsuranceRisk.id",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mb-2",
                                    attrs: {
                                      pressed: _vm.myToggleP,
                                      variant: "light",
                                    },
                                    on: {
                                      "update:pressed": function ($event) {
                                        _vm.myToggleP = $event
                                      },
                                      click: function ($event) {
                                        _vm.myToggleP
                                          ? _vm.selectAllProducts()
                                          : _vm.deselectAllProducts()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.myToggleP
                                          ? "Deseleziona tutti"
                                          : "Seleziona tutti"
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("b-row", [
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-input", {
                                  attrs: {
                                    name: "counterpart",
                                    type: "text",
                                    label: "Nominativo controparte",
                                    placeholder: "Inserisci una controparte",
                                  },
                                  on: { keyup: _vm.onEnterClaimParty },
                                  model: {
                                    value: _vm.counterpart,
                                    callback: function ($$v) {
                                      _vm.counterpart = $$v
                                    },
                                    expression: "counterpart",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-input", {
                                  attrs: {
                                    name: "referring_at",
                                    type: "number",
                                    label: "Anno",
                                    placeholder:
                                      "Inserisci anno di rubricazione",
                                  },
                                  model: {
                                    value: _vm.filter.byAttribute.referring_at,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter.byAttribute,
                                        "referring_at",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "filter.byAttribute.referring_at",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-input", {
                                  attrs: {
                                    name: "REPL",
                                    type: "text",
                                    label: "Targa Veicolo",
                                    placeholder: "Inserisci una targa",
                                  },
                                  model: {
                                    value: _vm.filter.byInsurancePolicy.REPL,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter.byInsurancePolicy,
                                        "REPL",
                                        $$v
                                      )
                                    },
                                    expression: "filter.byInsurancePolicy.REPL",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-select", {
                                  attrs: {
                                    name: "settled",
                                    label: "Definiti/Non Definiti",
                                    options: _vm.settled_options,
                                  },
                                  model: {
                                    value: _vm.filter.bySettled,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filter, "bySettled", $$v)
                                    },
                                    expression: "filter.bySettled",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-input", {
                                  attrs: {
                                    name: "surveyor",
                                    type: "text",
                                    label: "Perito",
                                    placeholder: "Inserisci un perito",
                                  },
                                  on: { keyup: _vm.onEnterClaimParty },
                                  model: {
                                    value: _vm.surveyor,
                                    callback: function ($$v) {
                                      _vm.surveyor = $$v
                                    },
                                    expression: "surveyor",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-input", {
                                  attrs: {
                                    name: "surveyor",
                                    type: "text",
                                    label: "Ispettorato",
                                    placeholder: "Inserisci un ispettorato",
                                  },
                                  on: { keyup: _vm.onEnterClaimParty },
                                  model: {
                                    value: _vm.inspector,
                                    callback: function ($$v) {
                                      _vm.inspector = $$v
                                    },
                                    expression: "inspector",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-input", {
                                  attrs: {
                                    name: "registry_group",
                                    type: "text",
                                    label: "Gruppo Anagrafico",
                                    placeholder: "Inserisci un gruppo",
                                  },
                                  model: {
                                    value: _vm.filter.byRegistryGroup.code,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter.byRegistryGroup,
                                        "code",
                                        $$v
                                      )
                                    },
                                    expression: "filter.byRegistryGroup.code",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-input", {
                                  attrs: {
                                    name: "CPRGP",
                                    type: "text",
                                    label: "Targa Controparte",
                                    placeholder: "Inserisci una targa",
                                  },
                                  on: { keyup: _vm.onEnterClaimParty },
                                  model: {
                                    value: _vm.CPRGP,
                                    callback: function ($$v) {
                                      _vm.CPRGP = $$v
                                    },
                                    expression: "CPRGP",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.customFilters.length,
                                  expression: "customFilters.length",
                                },
                              ],
                            },
                            [
                              _c("p", [_vm._v("Attributi personalizzati")]),
                              _vm._v(" "),
                              _c(
                                "b-row",
                                [
                                  _vm._l(
                                    _vm.customFilters.filter(
                                      (e) => _vm.getInputType(e) === "text"
                                    ),
                                    function (element, index) {
                                      return _c("custom-filter", {
                                        key: `A${index}`,
                                        attrs: { element: element },
                                        model: {
                                          value:
                                            _vm.filter.byCustom[element.key][
                                              "value"
                                            ],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.filter.byCustom[element.key],
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "filter.byCustom[element.key]['value']",
                                        },
                                      })
                                    }
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.customFilters.filter(
                                      (e) => _vm.getInputType(e) !== "text"
                                    ),
                                    function (element, index) {
                                      return _c("custom-filter", {
                                        key: `B${index}`,
                                        attrs: { element: element },
                                        model: {
                                          value:
                                            _vm.filter.byAttribute[element.key],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.filter.byAttribute,
                                              element.key,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "filter.byAttribute[element.key]",
                                        },
                                      })
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("export-options", {
            attrs: {
              exportUrl: _vm.exportUrl,
              repository: _vm.repository,
              resource: _vm.resource,
              tableRef: _vm.$refs[_vm.tableRef],
              filter: _vm.filter,
              options: [
                {
                  code: "EXPOCLAI",
                  label: null,
                  formats: ["csv", "pdf"],
                },
              ],
            },
          }),
          _vm._v(" "),
          _c("claims", {
            ref: _vm.tableRef,
            attrs: {
              fields: _vm.fields,
              repository: _vm.repository,
              resource: _vm.resource,
              filterName: _vm.filterName,
              filterOn: {
                byRelations: [
                  "byCorrespondence",
                  "byClaimParty",
                  "byBroker",
                  "byRegistryGroup",
                  "byTask",
                  "byDocument",
                ],
              },
              onlyActions: ["edit", "destroy", "infomodal"],
            },
            on: {
              edit: _vm.onEdit,
              destroy: _vm.onDestroy,
              open_note: _vm.openNoteModal,
              open_document: _vm.openDocumentModal,
            },
          }),
          _vm._v(" "),
          _c("note-detail-modal", { attrs: { items: _vm.notes } }),
          _vm._v(" "),
          _c("document-detail-modal", { attrs: { items: _vm.documents } }),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c("b-icon", { attrs: { icon: "info-circle", scale: "1.00" } }),
          _vm._v("\n  Non hai i permessi\n"),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }